<div class="cam-holder" matDialogContent>
    <webcam
            (cameraSwitched)="cameraWasSwitched($event)"
            (imageCapture)="handleImage($event)"
            (initError)="handleInitError($event)"
            [allowCameraSwitch]="allowCameraSwitch"
            [imageQuality]="1"
            [switchCamera]="nextWebcamObservable"
            [trigger]="triggerObservable"
            [videoOptions]="videoOptions"
            [width]="310"
    >
    </webcam>
    <div class="buttons-holder" matDialogActions>
        <button (click)="triggerSnapshot()" mat-raised-button>Ta bild</button>
        <div style="padding-left: 10px">
            <button (click)="onClose()" mat-raised-button>Stäng</button>
        </div>
    </div>
    <div *ngIf="webcamImage" class="snapshot">
        <h2>Nice one!</h2>
        <img [src]="webcamImage.imageAsDataUrl" alt="photo"/>
    </div>
</div>

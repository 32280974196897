import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {LoginComponent} from './1-login/login.component'
import {authGuard} from './application/auth.guard'
import {HOME_ROUTE_PATH} from './application/data-types'
import {RegisteredComponent} from './5-registered/registered.component'
import {WelcomeComponent} from './2-welcome/welcome.component'
import {SignComponent} from './4-sign/sign.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: HOME_ROUTE_PATH,
    component: WelcomeComponent,
    canActivate: [authGuard]
  },
  {
    path: 'sign/:token',
    component: SignComponent
  },
  {
    path: 'registered',
    component: RegisteredComponent,
    canActivate: [authGuard]
  },
  {
    path: '**',
    component: LoginComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

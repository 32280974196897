import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core'
import {MatTableDataSource} from '@angular/material/table'
import {MatSort} from '@angular/material/sort'
import {FormControl} from '@angular/forms'
import {first, startWith} from 'rxjs'
import '@angular/common/locales/global/fr'
import {PhotoService} from '../services/photo.service'
import {formatDate} from '@angular/common'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {DetailsDialogComponent} from '../dialogs/details-dialog/details-dialog.component'

export interface DisplayListItem {
  photographerSub: string
  timeStamp: string
  description?: string
  photoSubjectSub: string
  photo?: string
  itemId: string
  agreement: string
  version: number
}

@Component({
  selector: 'aku-registered',
  templateUrl: './registered.component.html',
  styleUrls: ['./registered.component.scss']
})
export class RegisteredComponent implements OnInit, AfterViewInit {
  @Output() handleSwipeRight = new EventEmitter<Event>()
  @ViewChild(MatSort) daSort = new MatSort()

  public columnsToDisplay: string[] = ['timeStamp', 'description', 'photo']

  public filterControl = new FormControl<string>('', {nonNullable: true})

  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>()

  public copyText = ''

  constructor(
    private photoService: PhotoService,
    private detailsDialog: MatDialog
  ) {
  }

  public ngOnInit() {
    this.filterControl.valueChanges.pipe(
      startWith('')
    ).subscribe({
      next: (value: string) =>
        this.dataSource.filter = value.trim().toLowerCase().replace('-', '')
    })

    this.photoService.getPhotos().pipe(first()).subscribe({
      next: (serverItem: DisplayListItem[]) => {
        this.dataSource = new MatTableDataSource<DisplayListItem>(serverItem)
        this.dataSource.sort = this.daSort
      }
    })
  }

  public saveInfo() {
    this.photoService.getPhotos().pipe(first()).subscribe({
      next: (serverItem: DisplayListItem[]) => {
        if (serverItem.length === 0) {
          this.copyText = 'Inga signeringar att spara'
          setTimeout(() => {
            this.copyText = ''
          }, 2500)
          return
        }
        let json_data = 'fotograf;' + 'modell;' + 'beskrivning;' + 'tidpunkt;' + 'signeringsid;' + '\n'
        serverItem.forEach((value: DisplayListItem) => {
          json_data = json_data + value.photographerSub + ';' + value.photoSubjectSub + ';' + value.description + ';' + formatDate(value.timeStamp, 'YYYY-MM-dd HH:mm', 'fr', '0200') + ';' + value.itemId + '\n'
        })
        const sub = serverItem[0].photographerSub
        const filename = `${sub}_signeringar.txt`

        // Create blog and url to click
        const blob = new Blob([json_data], {type: 'application/json'})
        const url = URL.createObjectURL(blob)
        const downloadLink = document.createElement('a')
        downloadLink.href = url
        downloadLink.download = filename
        downloadLink.click()

        // Clear the URL object after the download
        URL.revokeObjectURL(url)
      }
    })
  }

  public refreshData(): void {
    this.photoService.getPhotos().pipe(first()).subscribe({
      next: (serverItem: DisplayListItem[]) => {
        this.dataSource.data = serverItem
        this.dataSource.sort = this.daSort
      }
    })
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.daSort
  }

  public openDialog(item: DisplayListItem) {
    const detailsDialogRef: MatDialogRef<DetailsDialogComponent> = this.detailsDialog.open(DetailsDialogComponent, {
        data: item
      }
    )
    detailsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshData()
      }
    })
  }
}

import {Inject, Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Observable, of} from 'rxjs'
import {environment} from '../../../../environments/environment'
import {map, switchMap} from 'rxjs/operators'
import {WINDOW} from '../../../application/window.provider'

export interface IFileUpload {

  /**
   * The name of the file
   */
  name: string

  /**
   * The content type, we will possibly use this
   * later.
   */
  contentType: string

  /**
   * Image data is a "Blob"?
   */
  imageData: Blob

  /**
   * The id as received from the server
   */
  id?: string
}

interface PutRequestResult {
  /**
   * A one time link to use to upload an image to S3
   */
  signedUrl: string

  /**
   * The server side generated id <uuidv4>.<ext>
   */
  id: string
}

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(
    private httpClient: HttpClient,
    @Inject(WINDOW) private injectedWindow: Window
  ) {
  }

  /**
   * Need to rename this in the future and move it to a "file" service.
   */
  public uploadImageData(image: IFileUpload): Observable<IFileUpload> {
    const headers = {
      'Content-Type': image.contentType,
      'Cache-Control': 'max-age=4000'
    }
    let id = ''
    const url = `${environment.apiUrl}/images`
    return this.httpClient.put<PutRequestResult>(url, {contentType: image.contentType}, ).pipe(
      switchMap((r: PutRequestResult) => {
        console.log(r)
        id = (r.id)
        const httpOptions = {
          headers: new HttpHeaders(headers)
        }
        return this.httpClient.put<void>(r.signedUrl, image.imageData, httpOptions)
      }),
      map(() => {
        image.id = id
        return image
      })
    )
  }

  public getViewUrl(id: string): Observable<string> {
    const url = `${environment.apiUrl}/images/${id}`
    return this.httpClient.get(url).pipe(
      switchMap((res: any) => of(res.signedUrl))
    )
  }

  public openImage(id: string): void {
    this.getViewUrl(id).subscribe({
      next: (url: string) => this.injectedWindow.open(url, '_blank')
    })
  }

  public deleteImage(id: string): Observable<void> {
    const url = `${environment.apiUrl}/images/${id}`
    return this.httpClient.delete<void>(url)
  }
}

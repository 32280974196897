import {Inject, Injectable} from '@angular/core'
import {LOCAL_STORAGE} from '../application/local-storage.provider'
import {ACCESS_TOKEN_NAME} from '../application/types'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {HelperService} from '@akiru/bankid'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
  }

  public setToken(accessToken: string): void {
    this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, accessToken)
    this.isLoggedIn.next(true)
  }

  public checkLogoutTime(): number {
    const token: any = HelperService.GetTokenPayload(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
    if (token) {
      return token.exp
    } else {
      return 0
    }
  }

  public getCurrentUser(){
    const token: any = HelperService.GetTokenPayload(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
    return token
  }

  public checkLoggedIn():void {
    this.isLoggedIn.next(!!this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
  }

  public logout():void {
    this.isLoggedIn.next(false)
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
  }

  public createSessionData(comment: string): Observable<string> {
    // Put the photographers comment at the BE and generat and return session data
    return of('UFFE' + Date.now())
  }

  public getSessionData(): Observable<string> {
    return of('UFFE' + Date.now())
  }

  public sendEmail(data: any): Observable<boolean> {
    // Send to BE and return when done

    return of(true)
  }
}

<div class="container" (swipeleft)="handleSwipeLeft.emit()">
    <div class="sign-holder">
        <h3>Seal the Deal Foto</h3>
        I fältet nedan kan du göra en anteckning som kan hjälpa dig att identifiera objekten i bilden/bilderna vid ett
        senare tillfälle.
        <mat-form-field class="description">
            <mat-label>Anteckning (kan lämnas tom)</mat-label>
            <textarea
                    placeholder="Ex. Damen i röd basker"
                    cdkAutosizeMaxRows="3"
                    cdkAutosizeMinRows="3"
                    cdkTextareaAutosize
                    [formControl]="comment"
                    matInput></textarea>
        </mat-form-field>
        <div>
            <p>Om du har tagit en bild med din mobil som du vill ha samtycke för trycker du på knappen "Välj bild" och väljer bilden i tin telefon för att påbörja godkännandet av samtycket.</p>
            <p>Om du tagit bilder med en annan kamera som du vill ha samtycke för</p>
        </div>
        <div class="button-holder">
            <button mat-raised-button (click)="startSign()">Starta signering</button>
            <button mat-raised-button (click)="startSign()">Välj bild</button>
        </div>
    </div>
</div>
import {Component, inject, Inject, OnInit} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {first} from 'rxjs'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

@Component({
  selector: 'aku-start-sign-dialog',
  templateUrl: './start-sign-dialog.component.html',
  styleUrls: ['./start-sign-dialog.component.scss']
})
export class StartSignDialogComponent implements OnInit{

  public signUrl = ''
  private configService: ConfigService = inject(ConfigService)
  public dialogRef: MatDialogRef<StartSignDialogComponent> = inject(MatDialogRef<StartSignDialogComponent>)

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { comment: string }) {
  }

  ngOnInit(): void {
    this.configService.createSessionData(this.data.comment).pipe(
      first()
    ).subscribe({
      next: (token: string) => {
        this.signUrl = 'https://sealthedeal.se/sign/' + token
      }
    })
  }
}

<mat-tab-group #tabGroup>
    <mat-tab label="Välkommen">
        <ng-template matTabContent>
            <aku-home (handleSwipeLeft)="swipeLeft()"></aku-home>
        </ng-template>
    </mat-tab>
    <mat-tab label="Signerade">
        <ng-template matTabContent>
            <aku-registered (handleSwipeRight)="swipeRight()"></aku-registered>
        </ng-template>
    </mat-tab>
</mat-tab-group>

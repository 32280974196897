import {Component, EventEmitter, OnInit, Output} from '@angular/core'
import {environment} from '../../environments/environment'
import {FormControl} from '@angular/forms'
import {ConfigService} from '../services/config.service'
import {MatDialog} from '@angular/material/dialog'
import {StartSignDialogComponent} from '../dialogs/start-sign-dialog/start-sign-dialog.component'

@Component({
  selector: 'aku-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Output() handleSwipeLeft = new EventEmitter<Event>()
  public bankIdUrl = environment.apiUrl
  public service = environment.service
  public currentPhotographerSub = ''
  public currentDate = ''
  public comment: FormControl = new FormControl<string>('')
  public signUrl = ''

  constructor(private configService: ConfigService, private signDialog: MatDialog) {
  }

  public ngOnInit() {
    const user = this.configService.getCurrentUser()
    this.currentPhotographerSub = user?.sub as string

    const today = new Date()
    const year = today.getFullYear()
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    const day = today.getDate().toString().padStart(2, '0')
    this.currentDate = `${year}-${month}-${day}`
  }

  public afterSigned() {
    return this.handleSwipeLeft.emit()
  }

  public startSign(): void {
    this.signDialog.open(StartSignDialogComponent, {data: {comment: this.comment.value}}).afterClosed().subscribe({
      next: () => {
        this.signUrl = ''
        console.log('mjau')
        console.log(this.signUrl)
      }
    })
  }
}

import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import {DisplayListItem} from '../../5-registered/registered.component'
import {RegretDialogComponent} from '../regret-dialog/regret-dialog.component'
import {PhotoService} from '../../services/photo.service'
import {filter} from 'rxjs'

@Component({
  selector: 'aku-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss']
})
export class DetailsDialogComponent {
  public removeConsent = true

  constructor(
    private detailsDialogRef: MatDialogRef<DetailsDialogComponent>,
    private regretDialog: MatDialog,
    private photoService: PhotoService,
    @Inject(MAT_DIALOG_DATA) public data: DisplayListItem
  ) {
  }

  public openDialog(itemId: string): void {
    this.regretDialog.open(RegretDialogComponent, {data: itemId})
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe({
        next: () => {
          this.photoService.deleteItem(itemId).subscribe()
          this.detailsDialogRef.close(true)
        }
      })
  }
}
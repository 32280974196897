import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {ConfigService} from '../services/config.service'
import {first, switchMap} from 'rxjs'
import {environment} from '../../environments/environment'
import {MatDialog} from '@angular/material/dialog'
import {CameraDialogComponent} from '../dialogs/camera-dialog/camera-dialog.component'
import {WebcamImage} from 'ngx-webcam'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {ImagesService} from '../common/images/services/images.service'

@Component({
  selector: 'aku-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit {

  public contactForm: FormGroup = new FormGroup({
    name: new FormControl<string>(''),
    email: new FormControl<string>('', Validators.required),
    accessToken: new FormControl<string>('')
  })

  public bankIdUrl = environment.apiUrl
  public service = environment.service
  public image = ''
  public accessToken = ''
  public allDone = false
  private token: string | null = null
  private timeStamp = 0

  constructor(
    private route: ActivatedRoute,
    private configService: ConfigService,
    private router: Router, private camDialog: MatDialog,
    private imageService: ImagesService
  ) {
  }

  ngOnInit() {
    this.configService.getSessionData().pipe(
      first(),
      switchMap((token: string) => {
        this.token = token.slice(0, 4)
        this.timeStamp = +token.slice(4)
        return this.route.paramMap
      })
    ).subscribe({
      // next: (params: ParamMap) => {
      //   if (this.token !== params.get('token')?.slice(0, 4)) {
      //     // if (this.token !== params.get('token')?.slice(0, 4) || +params.get('token')?.slice(4) < Date.now() - (30 * 60 * 1000)) {
      //     this.router.navigate(['/']).then()
      //   }
      // }
    })
  }

  public openCam() {
    const dialogRef = this.camDialog.open(CameraDialogComponent, {}
    )
    dialogRef.afterClosed().subscribe((result: WebcamImage) => {
      this.image = result.imageAsDataUrl
      const mimeType = this.getMimeTypeFromDataUrl(result.imageAsDataUrl)
      const blob = this.createBlob(result.imageAsDataUrl)
      const image = {
        name: 'selfie',
        contentType: mimeType,
        imageData: blob
      }
      this.imageService.uploadImageData(image).subscribe({
        next: value => {
          console.log(value)
        }
      })
    })
  }

  // Get _mimeType from dataUrl
  private getMimeTypeFromDataUrl(dataUrl: string): string {
    const match = dataUrl.match(/^data:(.*?);/)
    if (match && match[1]) {
      return match[1]
    }
    return 'unknown'
  }

  public createBlob(base64: string) {
    const base64String = base64.split(',')[1]
    const binaryString = atob(base64String)
    const byteArray = new Uint8Array(binaryString.length)

    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i)
    }

    return new Blob([byteArray], {type: 'image/jpeg'})
  }

  public sendEmail(): void {
    this.configService.sendEmail(this.contactForm.getRawValue()).subscribe({
      next: () => this.allDone = true
    })
  }
}
